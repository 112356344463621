.VerificationForm {

}

.VerificationForm .Info {
    color: white;
}

.VerificationForm .AgreementWrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0 30px 0;
}

.VerificationForm .AgreementWrapper .AgreementItem {
    width: 350px;
    padding: 20px 10px;
    background-color: #222222;
    border-radius: 15px;
}
.VerificationForm .AgreementWrapper .AgreementItem a {
    color: white;
}

.VerificationForm .AgreementWrapper .AgreementError {
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 16px;
    max-width: 100%;
    padding: 5px 10px;
    margin: 0;
    color: #5E5E5E;
}
