.EmployeeInviteList .Button {
    padding: 0 20px 30px 20px;
}

.EmployeeInviteList .EmployeeInviteListItemWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem {
    color: white;
    margin: 10px 0;
    padding: 15px 5px;
    background-color: #ffffff12;
    border-radius: 2%;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteInfo {
    display: flex;
    flex-direction: column;
    margin: 5px;
    justify-content: center;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteInfo span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteInfo .EmployeeInviteName {
    margin: 5px 0 10px 0;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteInfo .EmployeeInvitePublicName,
.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteInfo .EmployeeInviteAddress,
.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteInfo .EmployeeInviteEmployees {
    margin-bottom: 5px;
    color: #9e9e9e;
    font-size: 14px;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteInfo svg {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 2px;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteControl {
    display: flex;
    width: 20%;
    align-self: center;
    margin-right: 10px;
    flex-direction: column;
    align-items: end;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral div {
    margin: 5px 0;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteControl svg {
    font-size: 28px;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteFooter {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteFooter .Button {
    padding: 5px;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteFooter .Button button {
    min-width: 70px;
    min-height: 35px;
    font-size: 16px;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 5px 0;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteActions .Button {
    width: 150px;
    padding: 10px 10px 0 10px;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteActions .Button button {
    min-width: 0;
    width: 120px;
    min-height: 4vh;
}

.EmployeeInviteList .EmployeeInviteListItems .NoData {
    display: inline-block;
    min-width: 80px;
    padding: 15px 10px;
    margin-top: 10px;
    color: #9e9e9e;
    background-color: #ffffff12;
    border-radius: 2%;
}

.EmployeeInviteList .EmployeeInviteListItems .EmployeeInviteItem .EmployeeInviteGeneral .EmployeeInviteInfo .SystemPmContent span {
    width: 280px;
    margin-left: 25px;
    overflow: visible;
    white-space: normal;
}
