.BranchList .Button {
    padding: 0 20px 30px 20px;
}

.BranchList .BranchListItemWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
}

.BranchList .BranchListItems .BranchItem {
    color: white;
    margin: 10px 0;
    padding: 15px 5px;
    background-color: #ffffff12;
    border-radius: 2%;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral {
    display: flex;
    min-height: 90px;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchInfo {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 5px 5px 5px 10px;
    text-align: left;
    justify-content: center;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchInfo span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchInfo .BranchName {
    margin: 5px 0 10px 0;
    color: white;
    font-size: 24px;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchInfo .BranchPublicName,
.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchInfo .BranchAddress,
.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchInfo .BranchEmployees {
    margin-bottom: 5px;
    color: #9e9e9e;
    font-size: 14px;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchInfo svg {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 2px;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchControl {
    display: flex;
    width: 20%;
    align-self: center;
    margin-right: 10px;
    flex-direction: column;
    align-items: end;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral div {
    margin: 5px 0;
}

.BranchList .BranchListItems .BranchItem .BranchGeneral .BranchControl svg {
    font-size: 28px;
}

.BranchList .BranchListItems .BranchItem .BranchFooter {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.BranchList .BranchListItems .BranchItem .BranchFooter .Button {
    padding: 10px;
}

.BranchList .BranchListItems .BranchItem .BranchFooter .Button button {
    min-width: 100px;
    min-height: 40px;
    font-size: 16px;
}

.BranchList .BranchListItems .BranchItem .BranchActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 5px 0;
}

.BranchList .BranchListItems .BranchItem .BranchActions .Button {
    width: 150px;
    padding: 10px 10px 0 10px;
}

.BranchList .BranchListItems .BranchItem .BranchActions .Button button {
    min-width: 0;
    width: 120px;
    min-height: 4vh;
}
