.SystemPmContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    margin: 0 auto;
    padding: 10px;
}

.SystemPmContainer .SystemPmWrapper {
    display: grid;
    width: 340px;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    background-color: #222222;
}

.SystemPmContainer .SystemPmWrapper .SystemPmIcon {
    position: absolute;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    color: white;
    font-size: 20px;
}

.SystemPmContainer .SystemPmWrapper .SystemPmContent {
    color: white;
    text-align: left;
    margin-left: 45px;
    margin-right: 10px;
}

.SystemPmContainer .SystemPmWrapper .SystemPmContent a {
    color: #DBFC08;
    text-decoration: none;
}
