.LoaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #070707;
    pointer-events: none;
}

.LoaderWrapper.WithTarget {
    position: absolute;
    z-index: 999;
    /*top: 0;*/
    left: 0;
    width: 100%;
    height: fit-content;
}

.LoaderWrapper.FullScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    overflow: hidden;
    z-index: 999;
    background: #070707;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
