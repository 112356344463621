.PaymentForm .AmountWrapper {
    display: inline-flex;
    width: 374px;
    justify-content: space-between;
    margin: 0 10px;
}

.PaymentForm .AmountWrapper .AmountSector {
    color: white;
    font-size: 32px;
    padding-top: 10px;
    margin-left: 10px
}

.PaymentForm .AmountWrapper .EditWrapper {
    margin-right: 10px;
}

.PaymentForm .AmountWrapper .EditWrapper .Button {
    padding: 0;
}

.PaymentForm .AmountWrapper .EditWrapper .Button button {
    color: white;
    background: #1F1F1F;
    min-width: 8vh;
}

.PaymentForm .Info {
    display: flex;
    margin: 10px 10px 25px 10px;
    justify-content: center;
}

.PaymentForm .Info span {
    color: white;
    max-width: 374px;
}
