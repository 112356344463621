.AccountWrapper .BalanceWrapper {
    color: white;
    font-weight: 700;
    margin: 0 0 30px 0;
}

.AccountWrapper .BalanceWrapper .BalanceItem {
    letter-spacing: 0.05em;
}

.AccountWrapper .BalanceWrapper .BalanceItem .BalanceMain {
    font-size: 28px;
}

.AccountWrapper .BalanceWrapper .BalanceItem .BalanceAdditional {
    font-size: 16px;
}

.AccountWrapper .TabControlWrapper {
    margin: 0 10px;
    font-size: 16px;
    font-weight: 700;
}

.AccountWrapper .TabControlWrapper a {
    color: white;
    margin: 0 10px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
}

.AccountWrapper .TabControlWrapper a.active {
    color: black;
    background-color: white;
}

.AccountWrapper .SystemNotifySection {
    margin-bottom: 35px;
}

.AccountWrapper .TabTitle {
    color: white;
    margin: 35px 0 0 0;
}

.AccountWrapper .NoData {
    display: inline-block;
    min-width: 80px;
    padding: 15px 10px;
    margin-top: 10px;
    color: #9e9e9e;
    background-color: #ffffff12;
    border-radius: 2%;
}
