.WithdrawalForm {

}

.WithdrawalForm .Info {
    color: white;
}

.WithdrawalForm .FormButtonsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.WithdrawalForm .AmountWrapper {
    display: inline-block;
    width: 360px;
    margin-bottom: 20px;
    color: white;
}

.WithdrawalForm .AmountWrapper .AmountSection {
    font-size: 20px;
}

.WithdrawalForm .AmountWrapper .FeeSection {
    font-size: 14px;
}

.WithdrawalForm .PayoutCreateTips {
    color: white;
    width: 360px;
    justify-self: center;
    margin: -10px auto 0 auto;
}

.WithdrawalForm .PayoutCreateTips a {
    color: white;
}
