.BranchForm {

}

.BranchForm .Info {
    color: white;
}

.BranchForm .FormButtonsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px
}
