.WalletList .Button {
    padding: 0 20px 30px 20px;
}

.WalletList .WalletListWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.WalletList .WalletListWrapper .WalletItem {
    width: 100%;
    max-width: 360px;
    color: white;
    margin: 10px 15px;
    padding: 15px 5px;
    background-color: #ffffff12;
    border-radius: 2%;
}

.WalletList .WalletListWrapper .WalletItem .WalletType {
    margin: 0 0 15px 0;
    display: flex;
}

.WalletList .WalletListWrapper .WalletItem .WalletType span {
    color: gray;
    font-size: 10px;
    margin-left: 10px;
    border: 1px solid gray;
    padding: 3px;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral {
    display: flex;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral .WalletIcon {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral .WalletControl {
    display: flex;
    width: 20%;
    align-self: center;
    margin-right: 10px;
    flex-direction: column;
    align-items: end;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral .WalletControl svg {
    color: white;
    font-size: 26px;
    cursor: pointer;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral .WalletIcon svg {
    font-size: 24px;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral .WalletInfo {
    width: 60%;
    text-align: left;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral .WalletInfo * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral .WalletInfo .WalletName {
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
}

.WalletList .WalletListWrapper .WalletItem .WalletGeneral .WalletInfo .WalletDetail {
    color: #9e9e9e;
}
