.lang-switcher .flag {
    font-size: 20px;
    align-self: center;
}

.lang-switcher .code {
    font-size: 18px;
    font-weight: 500;
    padding-top: 4px;
    align-self: center;
}

.lang-switcher .lang-select__control {
    padding-top: 5px;
}

.lang-switcher .lang-select__control:hover {
    cursor: pointer;
}

.lang-switcher .lang-select__menu, .lang-switcher .lang-select__menu div {
    background: #1F1F1F;
}

.lang-switcher .lang-select__menu div:first-child {
    margin-top: -5px;
}

.lang-switcher .lang-select__menu div:last-child {
    padding-bottom: 0;
}

.lang-switcher .lang-select__value-container > div {
    display: flex;
    flex-flow: row;
}

.lang-select__menu .my-option {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: left;
    padding-bottom: 5px;
    padding-left: 15px;
}

.lang-select__menu .my-option:hover {
    cursor: pointer;
    background-color: #0e0e0e !important;
}