.WalletForm {

}

.WalletForm .Info {
    color: white;
}

.WalletForm .FormButtonsWrapper {
    display: flex;
    justify-content: center;
}
