.AccountVerification {
}

.AccountVerification .Info {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0 20px 20px;
}

.AccountVerification .Info .Icon {
    font-size: 40px;
    margin: 0 0 15px;
}

.AccountVerification .Info span {
    font-size: 18px;
}
