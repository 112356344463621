.NotFoundWrapper {
    display: flex;
    justify-content: center;
    color: white;
}

.NotFoundWrapper .NotFoundBlock {
    width: 360px;
    padding: 10px;
}

.NotFoundWrapper .NotFoundBlock .NotFoundHeader {
    display: block;
    font-size: 52px;
    margin-bottom: 20px;
}
