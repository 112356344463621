.App-navbar {
    background-color: #070707;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-navbar .lang-switcher-wrapper {
    position: absolute;
    right: 2vh;
}
