.RegisterConfirmSection {

}

.RegisterConfirmSection .RegisterConfirmTimerWrapper {
    color: white;
}

.RegisterConfirmSection .RegisterConfirmTimerWrapper p {
    margin-top: 0;
    color: #5E5E5E;
    font-size: 14px;
}
