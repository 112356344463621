.PayForm {
    min-width: 374px;
    margin-bottom: 200px;
}

.PayForm .FormFooterSection {
    display: flex;
    width: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    position: fixed;
    bottom: 0;
    background-color: #070707;
}

.PayForm .FormFooterSection > div {
    width: 374px;
}

.PayForm .FormFooterSection .FormAgreementWrapper {
    color: #5E5E5E;
    padding: 0 20px 15px;
}

.PayForm .FormFooterSection .FormAgreementWrapper a {
    color: #DBFC08;
    text-decoration: none;
}

.PayForm .FormFooterSection .FormButtonsWrapper button {
    width: 100%;
    max-width: 20vh;
    min-height: 6vh;
}

.LandingScreen .AmountVariantsWrapper {
    display: inline-flex;
    padding-bottom: 20px;
    width: 374px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.LandingScreen .AmountVariantsWrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.LandingScreen .AmountSelector.Preset label {
    color: var(--text-icons-white, #FFF);
    background: var(--background-grey, #1F1F1F);
    margin: 0 5px;
    padding: 20px;
    min-width: 50px;
    height: 25px;
    border-radius: 20px;
    font-size: 18px;
    -webkit-tap-highlight-color: transparent;
}

.LandingScreen .AmountSelector.Preset label:hover {
    cursor: pointer;
}

.LandingScreen .AmountSelector.Preset label:focus {
    outline: none;
}

.LandingScreen .AmountSelector.Preset label.selected {
    background: #DBFC08;
    color: #070707;
}

.LandingScreen .AmountSelector.Custom label.selected input {
    border-color: #DBFC08;
}

.LandingScreen .AmountSelector .Field {
    margin: 0;
}

.LandingScreen .AmountSelector .Field .FieldWrapper {
    height: 15px;
    margin-top: 0;
    min-width: auto;
    width: 0;
}

.LandingScreen .AmountSelector.Custom .FieldWrapper {
    width: 189px;
}

.LandingScreen .AmountSelector.Custom .FieldWrapper input {
    color: white;
    margin: 0 5px;
    height: 65px;
}

.LandingScreen .AmountSelector.Custom .FieldWrapper input.selected {
    border: 3px solid #DBFC08;
    background: #DBFC08;
    color: black;
}

.LandingScreen .AmountSelector.Custom .FieldWrapper input.selected:focus {
    background: var(--background-grey, #1F1F1F);
    color: white;
}

.LandingScreen .AmountOptionsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 25px;
}

.LandingScreen .AmountOptionsWrapper .CommissionCheckboxWrapper {
    width: 374px;
    text-align: left;
}

.LandingScreen .AmountOptionsWrapper label span {
    font-weight: bold;
}

.SuccessScreen {
    display: inline-block;
    width: 374px;
}

.SuccessScreen .SuccessScreenContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SuccessScreen .SuccessScreenContent > div {
    width: 100%;
    margin: 5px;
}

.SuccessScreen .SuccessScreenContent > div span {
    color: white;
    font-size: 20px;
}
