nav .NavbarControl {
    position: absolute;
    top: 0;
    right: 4vh;
    height: 73px;
    margin-right: 10px;
}

nav .NavbarControl button {
    border: none;
    user-select: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

nav .NavbarControl button:after {
    content: "";
    display: block;
    position: absolute;
    height: 150%;
    width: 47px;
    top: 0;
    left: -25%;
}

nav .NavbarControl .NavbarControlLines {
    top: 50%;
    margin-top: -0.125em;
    height: 2px;
    pointer-events: none;
    display: block;
    content: "";
    width: 30px;
    background-color: white;
    position: absolute;
}

nav .NavbarControl .NavbarControlLines:before,
nav .NavbarControl .NavbarControlLines:after {
    height: 2px;
    pointer-events: none;
    display: block;
    content: "";
    width: 100%;
    background-color: white;
    position: absolute;
}

nav .NavbarControl .NavbarControlLines:before {
    top: 0.6rem;
}

nav .NavbarControl .NavbarControlLines:after {
    top: -0.6rem;
}

nav .NavbarWrapper .NavLangSwitcher {
    width: 100%;
    margin-top: -45px;
    margin-bottom: 20px;
    align-self: center;
}

nav .NavbarWrapper .NavLangSwitcher .lang-switcher .lang-select__menu,
nav .NavbarWrapper .NavLangSwitcher .lang-switcher .lang-select__control,
nav .NavbarWrapper .NavLangSwitcher .lang-switcher .lang-select__menu,
nav .NavbarWrapper .NavLangSwitcher .lang-switcher .lang-select__menu div
{
    background-color: #070707 !important;
}

nav .NavbarWrapper .NavLangSwitcher .lang-select__menu .my-option:hover {
    background-color: #151515 !important;
}

nav .NavbarWrapper ul {
    display: flex;
    flex-direction: column;
    position: fixed;
    margin: 0;
    right: 0;
    top: 0;
    padding: 70px 2rem 1rem;
    height: 100%;
    min-width: 160px;
    max-width: 200px;
    list-style: none;
    z-index: 999;
    background-color: #1F1F1F;
    transition: 0.2s ease-out;
    transform: translateX(100%);
}

nav .NavbarWrapper.open ul {
    transform: translateX(0);
}

nav .NavbarWrapper li {
    padding: 0.3rem 0;
    cursor: pointer;
    text-align: right;
}

nav .NavbarWrapper a {
    margin: 10px;
    font-size: 24px;
    font-weight: 500;
    color: white;
    text-decoration: none;
}