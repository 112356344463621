
.CardWrapper .MetaWrapper {
    display: inline-flex;
    place-content: flex-end;
    width: 360px;
    margin-top: -2vh;
}

.CardWrapper .MetaWrapper .ExpDateWrapper {
    display: block;
    width: 235px;
}

.CardWrapper .MetaWrapper .ExpDateLabelWrapper {
    font-size: 20px;
    line-height: 24px;
    color: #5E5E5E;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 40px;
    justify-content: center;
    text-align: center;
}

.CardWrapper .MetaWrapper .ExpDateWrapper div:last-child:not(.ErrorWrapper) {
    margin-left: 0;
    margin-right: 15px;
}

.CardWrapper .MetaWrapper .ExpDateWrapper .ErrorWrapper {
    width: 80%;
}

.CardWrapper .MetaWrapper .ExpDateWrapper .FieldWrapper {
    min-width: 0;
    width: 100px;
}

.CardWrapper .MetaWrapper .ExpDateFieldWrapper {
    display: inline-flex;
    justify-content: flex-start;
    margin-top: -20px;
}

.CardWrapper .MetaWrapper .ExpDateFieldWrapper div {
    width: 100px;
}

.CardWrapper .MetaWrapper .ExpDateWrapper .ExpMonthWrapper {
    margin-right: 10px;
}

.CardWrapper .MetaWrapper .CvcWrapper {
    width: 120px;
}

.CardWrapper .MetaWrapper .CvcWrapper .FieldWrapper {
    min-width: 0;
    width: 122px;
}
