.FileField {
    display: inline-grid;
    min-width: 360px;
    justify-content: center;
}

.FileField .Field {
    margin: -1vh 0 1vh;
}

.FileField .preview {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: left;
    padding: 20px;
}

.FileField input {
    display: none;
}

.FileField .preview > div {
    color: #5E5E5E;
    background-color: #1F1F1F;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 10px;
    border: 3px solid #5E5E5E;
    margin: 5px;
}

.FileField .preview > div span {
    display: inline-block;
}

.FileField .preview .FilePreview {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FileField .preview .ControlClose {
    margin-left: 10px;
}

.FileField .preview .ControlClose:hover {
    cursor: pointer;
    color: hsl(0, 0%, 60%);
}

.FileField .Button {
    justify-content: left;
}

.FileField .Button button {
    min-width: 120px;
}

.FileField .FileFieldWrapper {
    display: block;
    width: 360px;
    background: #1F1F1F;
    border-radius: 15px;
    border: 3px solid transparent;
}

.FileField .FileFieldWrapper .ControlWrapper {
    display: flex;
    flex-flow: row;
}

.FileField .FileFieldWrapper .ControlWrapper .ControlTips {
    margin-top: 20px;
    text-align: left;
    color: #5E5E5E
}

.FileField .FileFieldWrapper .ControlWrapper .ControlTips div {
    margin: 0 10px 10px 0;
}

.FileField .FileFieldWrapper .ControlWrapper .ControlTips .TipsWrapper {
    color: hsl(0, 0%, 50%);
}

.FileField .FileFieldWrapper .ControlWrapper .Button button {
    font-weight: 400;
}

.FileField .Error {
    width: 360px;
    font-size: 20px;
    line-height: 24px;
    color: #5E5E5E;
}