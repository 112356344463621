.LandingForm {

}

.LandingForm .Info {
    color: white;
}

.LandingForm .FormButtonsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px
}
