.CheckboxField {
    color: white;
}

.CheckboxField .CheckboxErrorWrapper {
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 16px;
    max-width: 100%;
    padding: 5px 10px;
    margin: 0;
    color: #5E5E5E;
}
