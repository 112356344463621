.PbWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.PbWrapper .PbCustomSection .PbCustomBody .PbCustomImage {
    width: 100%;
    min-width: 320px;
    max-width: 390px;
}

.PbWrapper .PbNativeSection {
    min-width: 320px;
    max-width: 360px;
    width: fit-content;
    margin: auto;
    display: flex;
    flex-flow: row;
    background-color: rgb(35 35 35 / 90%);
    padding: 15px;
}

.PbWrapper .PbNativeSection .PbNativeLeft {
    display: flex;
    flex-flow: row;
    width: 82%;
    cursor: default;
    line-height: 1.2;
}

.PbWrapper .PbNativeSection .PbNativeLeft .PbNativeImageBlock {
    margin: auto 0;
}

.PbWrapper .PbNativeSection .PbNativeLeft .PbNativeImageBlock .PbNativeImage {
    width: 50px;
    height: 50px;
}

.PbWrapper .PbNativeSection .PbNativeLeft .PbNativeContentBlock {
    margin: auto 0 auto 15px;
}

.PbWrapper .PbNativeSection .PbNativeLeft .PbNativeContentBlock .PbNativeTitle {
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    text-align: left;
    max-height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PbWrapper .PbNativeSection .PbNativeLeft .PbNativeContentBlock .PbNativeDescription {
    font-size: 14px;
    color: #767676;
    text-align: left;
    max-height: 68px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PbWrapper .PbNativeSection .PbNativeRight {
    width: 18%;
    margin: auto 10px auto 15px;
    text-align: right;
}

.PbWrapper .PbNativeSection .PbNativeRight .PbNativeButton {
    background-color: #dbfc08;
    color: black;
    font-weight: bold;
    padding: 3px 20px;
    border-radius: 8px;
    font-size: 14px;
    min-width: auto;
    min-height: 25px;
}
