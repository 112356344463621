.StaticPage {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.StaticPage .StaticPageWrapper {
    width: 374px;
    color: white;
}

.StaticPage .StaticPageWrapper .StaticPageContent {
    text-align: left;
    margin: 0 10px;
}

.StaticPage .StaticPageWrapper .StaticPageContent a {
    color: rgb(219, 252, 8);
    text-decoration: none;
}

.StaticPage .StaticPageWrapper .StaticPageContent ul {
    padding-inline-start: 14px;
}

.StaticPage .StaticPageWrapper .StaticPageContent ul li {
    margin: 10px 0;
}
