.PayHeader {
    display: inline-flex;
    flex-direction: row;
    width: 374px;
    margin-bottom: 25px;
}

.PayHeader .PayHeaderLogo img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 80px;
}

.PayHeader .PayHeaderInfo {
    color: white;
    padding: 0 0 0 20px;
    text-align: left;
    align-content: center;
}

.PayHeader .PayHeaderInfo .PayHeaderName {
    font-size: 21px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PayHeader .PayHeaderInfo .PayHeaderSubName {
    font-size: 15px;
    color: rgb(128, 128, 128);
    max-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
}
