.PasswordField {
    display: flex;
    width: auto;
    align-items: flex-start;
}

.PasswordField .PasswordVisibilityToggle {
    cursor: pointer;
    position: relative;
    align-content: center;
    height: 3.2rem;
    width: 36px;
    top: 3.2rem;
    padding-top: 0.3rem;
    margin-left: -40px;
    border-radius: 0 20px 20px 0;
    background-color: #1F1F1F;
}

.PasswordField .PasswordVisibilityToggle svg {
    font-size: 25px;
    color: #5E5E5E;
}
