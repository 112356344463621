.PwaInstallerManualNotificationWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    z-index: 10;
    transition: top 0.5s ease-in-out;
}

.PwaInstallerManualNotificationWrapper.visible {
    top: 0;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-content: center;
    width: 100%;
    height: 100%;
    padding: 20px 15px 20px 15px;
    background-color: rgba(25, 25, 25, 0.8);
    backdrop-filter: blur(5px);
    color: white;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection .PwaInstallerManualNotificationContent {
    width: 300px;
    margin: 0 auto;
    justify-self: center;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection .PwaInstallerTitleWrapper {
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection .PwaInstallerDescriptionWrapper {
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection .PwaInstallerDescriptionWrapper ol {
    padding-inline-start: 30px;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection .PwaInstallerNoteWrapper {
    font-size: 14px;
    text-align: center;
    margin-bottom: 35px;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection .PwaInstallerButtonsWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection .PwaInstallerButtonsWrapper button {
    font-size: 14px;
    border-radius: 20px;
    min-height: 1vh;
    padding: 10px 20px;
    width: 180px;
    border: none;
    cursor: pointer;
}

.PwaInstallerManualNotificationWrapper .PwaInstallerManualNotificationSection .PwaInstallerButtonsWrapper button {
    margin-bottom: 15px;
    background-color: #DBFC08;
    color: #070707;
    font-size: 18px;
    font-weight: 700;
}
