.PrivacyNotifyWrapper {
    position: fixed;
    z-index: 1;
    transition: bottom 0.5s ease-in-out;
}

.PrivacyNotifyWrapper.hide {
    bottom: -100%
}

.PrivacyNotifyWrapper .CookieConsent {
    align-items: center !important;
    justify-content: center;
    background-color: #191919FF !important;
    padding-top: 10px;
}

.PrivacyNotifyWrapper .CookieConsent .PrivacyPolicyContentWrapper {
    flex: 1 0 180px !important;
}

.PrivacyNotifyWrapper .CookieConsent .PrivacyNotifyText a {
    color: #DBFC08;
    text-decoration: none;
}

.PrivacyNotifyWrapper .CookieConsent .PrivacyPolicyButtonWrapper {
    margin-right: 10px;
}

.PrivacyNotifyWrapper .CookieConsent .PrivacyPolicyButtonWrapper .PrivacyPolicyButton {
    background-color: #DBFC08 !important;
    color: #070707 !important;
    font-size: 18px;
    border-radius: 20px !important;
    min-height: 2vh;
    padding: 10px 20px !important;
}
