.BranchInfo {
    display: flex;
    flex-flow: column;
    width: 360px;
    margin: 30px auto 15px;
}

.BranchInfo div {
    padding: 5px;
    color: white;
}

.BranchInfo .BranchInfoName {
    font-size: 22px;
    font-weight: bold;
}

.BranchInfo .BranchInfoAddress {
    font-size: 14px;
}

.BranchInfo .BranchInfoAddress svg {
    font-size: 24px;
    vertical-align: middle;
}

.BranchPaymentPageList .Button {
    padding: 0 20px 30px 20px;
}

.BranchPaymentPageList .BranchPaymentPageListItemWrapper {
    display: flex;
    flex-flow: column;
    width: 360px;
    margin: 0 auto;
}

.BranchPaymentPageList .BranchPaymentPageItem img {
    max-width: 300px;
    max-height: 300px;
    border-radius: 100%;
}

.QrWrapper {
    display: block;
    width: 100%;
    text-align: center;
}

.QrWrapper svg, .QrWrapper img {
    max-width: 330px;
    max-height: 330px;
}

.QrWrapper .ButtonContentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.QrWrapper .ButtonContentWrapper svg {
    color: black;
    font-size: 20px;
    margin-left: 5px;
}

.QrWrapper .QrCloseWindow {
    color: white;
    font-size: 38px;
    padding-bottom: 8%;
}

.QrWrapper .QrCodeWrapper {
    margin-bottom: 8%;
}

.QrWrapper .Button {
    padding: 10px;
}
.QrWrapper .Button button {
    width: 185px;
}

.BranchPaymentPageList .BranchPaymentPageListItems {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem {
    color: white;
    margin: 10px 0;
    padding: 15px 5px;
    background-color: #ffffff12;
    border-radius: 2%;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageOwner {
    text-align: left;
    margin-top: 5px;
    margin-left: 10px;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageOwner .BranchPaymentPageOwnerLabel {
    padding: 3px 8px;
    font-size: 12px;
    font-weight: bold;
    color: black;
    background-color: #dbfc08;
    border-radius: 5px;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageOwner .BranchPaymentPageOwnerLabel svg {
    font-size: 18px;
    vertical-align: top;
    margin-right: 5px;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral {
    display: flex;
    min-height: 100px;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageInfo {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 5px;
    text-align: left;
    justify-content: center;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageInfo span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageInfo .BranchPaymentPageName {
    margin: 5px 0 5px 0;
    color: white;
    font-size: 20px;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageInfo .BranchPaymentPageTitle {
    margin-bottom: 2px;
    color: #9e9e9e;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageInfo .BranchPaymentPageSubtitle {
    margin-bottom: 5px;
    color: #9e9e9e;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageAvatar {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageAvatar img {
    width: 100%;
    max-width: 300px;
    border-radius: 100%;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageControl {
    display: flex;
    width: 20%;
    align-self: center;
    margin-right: 10px;
    flex-direction: column;
    align-items: end;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral div {
    margin: 7px 0;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageGeneral .BranchPaymentPageControl svg {
    font-size: 24px;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 5px 0;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageActions .Button {
    width: 150px;
    padding: 10px 10px 0 10px;
}

.BranchPaymentPageList .BranchPaymentPageListItems .BranchPaymentPageItem .BranchPaymentPageActions .Button button {
    min-width: 0;
    width: 120px;
    min-height: 4vh;
}
