.Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.Button button {
    all: unset;

    background: #DBFC08;
    border-radius: 20px;
    min-width: 12vh;
    min-height: 42px;
    color: #070707;
    font-size: 18px;
    font-weight: 700;
    padding: 5px 20px;
}

.Button button:hover {
    cursor: pointer;
}

.Button button:disabled {
    cursor: not-allowed;
    background-color: #dbfc089c;
}
