.AvatarSection {
    width: 360px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 auto;
}

.AvatarSection .AvatarField .AvatarFieldWrapper {
    display: block;
    padding: 20px 0;
    background: #1F1F1F;
    border-radius: 15px;
    border: 3px solid transparent;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarFormSection .Button button {
    font-weight: 400;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarCropSection .Button {
    padding: 10px;
    margin-bottom: 10px;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarCropSection .Button button {
    min-width: fit-content;
    width: 110px;
    font-weight: 400;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarCropSection .reactEasyCrop_CropArea {
    border-radius: 50%;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarFormSection .ControlWrapper {
    display: flex;
    flex-flow: row;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarFormSection .ControlWrapper .ControlTips {
    margin: 10px 5px 10px 0;
    align-content: center;
    text-align: left;
    color: #5E5E5E;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarFormSection .ControlWrapper .ControlTips .TipsWrapper {
    color: hsl(0, 0%, 50%);
}

.AvatarSection .AvatarLoaderSection .LoaderWrapper {
    background: transparent;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarCurrentWrapper {
    margin: 10px 0;
}

.AvatarSection .AvatarField .AvatarFieldWrapper .AvatarCurrentWrapper img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
}
