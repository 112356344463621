.BranchInviteList .Button {
    padding: 0 20px 30px 20px;
}

.BranchInviteList .BranchInviteListItemWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem {
    color: white;
    margin: 10px 0;
    padding: 15px 5px;
    background-color: #ffffff12;
    border-radius: 2%;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteInfo {
    display: flex;
    flex-direction: column;
    margin: 5px 5px 5px 10px;
    justify-content: center;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteInfo span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteInfo .BranchInviteName {
    margin: 5px 0 10px 0;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteInfo .BranchInvitePublicName,
.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteInfo .BranchInviteAddress,
.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteInfo .BranchInviteEmployees {
    margin-bottom: 5px;
    color: #9e9e9e;
    font-size: 14px;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteInfo svg {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 2px;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteControl {
    display: flex;
    width: 20%;
    align-self: center;
    margin-right: 10px;
    flex-direction: column;
    align-items: end;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral div {
    margin: 5px 0;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteGeneral .BranchInviteControl svg {
    font-size: 28px;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteFooter {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteFooter .Button {
    padding: 5px;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteFooter .Button button {
    min-width: 70px;
    min-height: 35px;
    font-size: 16px;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 5px 0;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteActions .Button {
    width: 150px;
    padding: 10px 10px 0 10px;
}

.BranchInviteList .BranchInviteListItems .BranchInviteItem .BranchInviteActions .Button button {
    min-width: 0;
    width: 120px;
    min-height: 4vh;
}
