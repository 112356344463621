.ProfileWrapper {

}

.ProfileWrapper .ProfileHeader {
    width: 345px;
    display: inline-block;
    margin: 10px 10px 30px;
    padding: 5px 5px 20px 5px;
    background-color: #ffffff12;
    border-radius: 2%;
}

.ProfileWrapper .ProfileHeader .ProfileUsername {
    display: inline-block;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    color: white;
    max-width: 360px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.ProfileWrapper .ProfileUsername .ProfileAccType {
    margin-bottom: 15px;
}

.ProfileWrapper .ProfileUsername .ProfileAccType .ProfileAccTypeLabel {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: 5px;
    padding: 3px 8px;
    background-color: #dbfc08;
    color: black;
}

.ProfileWrapper .ProfileHeader .ProfileTariff {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-top: 20px;
}

.ProfileWrapper .ProfileHeader .ProfileTariff .TariffHeader {
    text-align: left;
    margin: 0 0 10px 10px;
    font-size: 14px;
    color: #9e9e9e;
}

.ProfileWrapper .ProfileHeader .ProfileTariff .TariffWrapper .TariffRowWrapper {
    display: flex;
    flex-flow: row;
}

.ProfileWrapper .ProfileHeader .ProfileTariff .TariffWrapper .TariffRowWrapper:first-child {
    margin-bottom: 15px;
}

.ProfileWrapper .ProfileHeader .ProfileTariff .TariffWrapper .TariffRowWrapper .TariffIcon {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 0 10px;
}

.ProfileWrapper .ProfileHeader .ProfileTariff .TariffWrapper .TariffRowWrapper .TariffIcon svg {
    font-size: 32px;
}

.ProfileWrapper .ProfileHeader .ProfileTariff .TariffWrapper .TariffRowWrapper .TariffContent {
    display: flex;
    flex-flow: column;
    text-align: left;
}

.ProfileWrapper .ProfileHeader .TariffRowWrapper .TariffContent .TariffSubHeader {
    font-size: 16px;
    margin-bottom: 5px;
}

.ProfileWrapper .ProfileHeader .TariffRowWrapper .TariffContent .TariffValue {
    font-size: 12px;
    color: #9e9e9e;
}

.ProfileWrapper .ProfileSection {
    margin-bottom: 35px;
}

.ProfileWrapper .ProfileSection .ReVerifyWrapper {
    display: block;
    margin-top: 5px;
}

.ProfileWrapper .ProfileSection .ReVerifyWrapper a {
    color: white;
}

.ProfileWrapper .SettingsNavbar {
    display: flex;
    flex-flow: row;
    margin-bottom: 25px;
    justify-content: center;
}

.ProfileWrapper .SettingsNavbar .SettingsNavbarWrapper {
    width: 360px;
    text-align: left;
    background-color: #ffffff12;
    border-radius: 2%;
}

.ProfileWrapper .SettingsNavbar .SettingsNavbarWrapper .SettingsNavItem {
    margin: 20px;
    font-weight: 500;
    font-size: 16px;
}

.ProfileWrapper .SettingsNavbar .SettingsNavbarWrapper .SettingsNavItem a {
    color: white;
    text-decoration: none;
}

.ProfileWrapper .SettingsNavbar .SettingsNavbarWrapper .SettingsNavItem .SettingsNavIcon {
    vertical-align: middle;
    font-size: 30px;
    margin-right: 10px;
}

.ProfileWrapper .SettingsNavbar .SettingsNavbarWrapper .SettingsNavItem .SettingsNavText {
    display: inline-block;
    vertical-align: middle;
    height: 25px;
}
