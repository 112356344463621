.PromoBanners .PromoBannersStatsWrapper .PromoBannerTable {
    display: flex;
    flex-direction: column;
    width: 360px;
    margin: 0 auto;
    padding: 0 10px;
}

.PromoBanners .PromoBannersStatsWrapper .PromoBannerTable .PromoBannerRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}

.PromoBanners .PromoBannersStatsWrapper .PromoBannerTable .PromoBannerRow .PromoBannerCell {
    flex: 1;
    padding: 10px;
    text-align: center;
    color: white;
    margin: auto;
    font-size: 14px;
}

.PromoBanners .PromoBannersStatsWrapper .PromoBannerTable .PromoBannerRow.header .PromoBannerCell {
    background-color: #f0f0f0;
    font-weight: bold;
    color: black;
}

.PromoBanners .PromoBannersStatsWrapper .PromoBannerNoData {
    display: inline-block;
    min-width: 80px;
    padding: 15px 10px;
    margin-top: 10px;
    color: #9e9e9e;
    background-color: #ffffff12;
    border-radius: 2%;
}
