.PrevButtonSection {
    text-align: left;
}

.PrevButtonSection .PrevButtonWrapper {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.PrevButtonSection .PrevButtonWrapper .PrevButtonBlock {
    margin-left: 10px;
}
