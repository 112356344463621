.PaymentPageList .Button {
    padding: 0 20px 30px 20px;
}

.PaymentPageList .PaymentPageItem img {
    max-width: 300px;
    max-height: 300px;
    border-radius: 100%;
}

.QrWrapper {
    display: block;
    width: 100%;
    text-align: center;
}

.QrWrapper svg, .QrWrapper img {
    max-width: 330px;
    max-height: 330px;
}

.QrWrapper .ButtonContentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.QrWrapper .ButtonContentWrapper svg {
    color: black;
    font-size: 20px;
    margin-left: 5px;
}

.QrWrapper .QrCloseWindow {
    color: white;
    font-size: 38px;
    padding-bottom: 8%;
}

.QrWrapper .QrCodeWrapper {
    margin-bottom: 8%;
}

.QrWrapper .Button {
    padding: 10px;
}
.QrWrapper .Button button {
    width: 185px;
}

.PaymentPageList .PaymentPageListItems .PaymentPageListItemWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem {
    width: 100%;
    max-width: 360px;
    color: white;
    margin: 10px 15px;
    padding: 15px 5px;
    background-color: #ffffff12;
    border-radius: 2%;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral {
    display: flex;
    min-height: 90px;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageInfo {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 5px;
    text-align: left;
    justify-content: center;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageInfo span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageInfo .PaymentPageName {
    margin: 5px 0 10px 0;
    color: white;
    font-size: 20px;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageInfo .PaymentPageTitle {
    margin-bottom: 2px;
    margin-left: 2px;
    color: #9e9e9e;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageInfo .PaymentPageSubtitle {
    margin-bottom: 8px;
    margin-left: 2px;
    color: #9e9e9e;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageInfo .PaymentPageAddress {
    margin-bottom: 8px;
    color: #9e9e9e;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageInfo .PaymentPageAddress svg {
    font-size: 24px;
    vertical-align: middle;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageAvatar {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageAvatar img {
    width: 100%;
    max-width: 300px;
    border-radius: 100%;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageControl {
    display: flex;
    width: 20%;
    align-self: center;
    margin-right: 10px;
    flex-direction: column;
    align-items: end;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral div {
    margin: 10px 0;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageGeneral .PaymentPageControl svg {
    font-size: 24px;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 5px 0;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageActions .Button {
    width: 150px;
    padding: 10px 10px 0 10px;
}

.PaymentPageList .PaymentPageListItems .PaymentPageItem .PaymentPageActions .Button button {
    min-width: 0;
    width: 120px;
    min-height: 4vh;
}

.PaymentPageList .PaymentPageListItems .NoData {
    display: inline-block;
    min-width: 80px;
    padding: 15px 10px;
    margin-top: 10px;
    color: #9e9e9e;
    background-color: #ffffff12;
    border-radius: 2%;
}
