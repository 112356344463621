.Field {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.Field input:autofill,
.Field input:-webkit-autofill {
    -webkit-text-fill-color: #5E5E5E;
    -webkit-box-shadow: 0 0 0 1000px #1F1F1F inset;
    border: 3px solid #5E5E5E;
}

.Field .FieldWrapper {
    width: 360px;
}

.Field input {
    all: unset;

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: inherit;
    height: 62px;
    background: #1F1F1F;
    border: 3px solid #5E5E5E;
    border-radius: 20px;
    flex: none;
    order: 3;
    flex-grow: 0;
    margin-top: 0.5rem;

    font-size: 18px;
    line-height: 32px;
    color: #5E5E5E;
    text-align: center;
}

.Field input:hover {
    cursor: text;
}

.Field input:focus {
    border: 3px solid #DBFC08;
}

.Field label {
    font-size: 16px;
    line-height: 24px;
    color: #5E5E5E;
}

.Field input:disabled {
    cursor: not-allowed;
}

.Field .ErrorWrapper {
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 16px;
    max-width: 100%;
    padding: 5px 10px;
    margin: 0;
    color: #5E5E5E;
}
