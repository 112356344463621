.TransactionWrapper {
    color: white;
}

.TransactionWrapper .TransactionList {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.TransactionWrapper .TransactionList .DateHeader {
    min-width: 360px;
    margin-top: 10px;
    margin-left: 10px;
}

.TransactionWrapper .TransactionList .DateHeader .DateContent {
    text-align: left;
    color: #9e9e9e;
}

.TransactionWrapper .TransactionList .TransactionItem {
    width: 100%;
    max-width: 360px;
    color: white;
    margin: 10px 15px;
    padding: 15px 5px;
    background-color: #ffffff12;
    border-radius: 2%;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionOwner {
    text-align: left;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 15px;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionOwner .TransactionOwnerLabel {
    padding: 3px 8px;
    font-size: 12px;
    font-weight: bold;
    color: black;
    background-color: #dbfc08;
    border-radius: 5px;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionOwner .TransactionOwnerLabel svg {
    font-size: 18px;
    vertical-align: top;
    margin-right: 5px;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral {
    display: flex;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionInfo {
    width: 50%;
    text-align: left;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionInfo .TransactionTitle {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionInfo .TransactionStatus {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #9e9e9e;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionInfo .TransactionDate {
    display: block;
    font-size: 14px;
    color: #9e9e9e;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionDir {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionDir svg {
    font-size: 24px;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionTotal {
    display: flex;
    width: 30%;
    margin-right: 10px;
    flex-direction: column;
    align-items: end;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionTotal .TransactionAmount {
    font-size: 18px;
    font-weight: 500;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionTotal .TransactionFee {
    font-size: 12px;
    color: white;
    margin-top: 5px;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionTotal .TransactionFee .FeeTooltip {
    font-size: 14px;
    color: #9e9e9e;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionTotal .TransactionFee .FeeTooltip svg {
    font-size: 14px;
    color: #9e9e9e;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionGeneral .TransactionTotal .TransactionFee .FeeTooltip .FeeAmount {
    margin-left: 5px;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionMeta {
    text-align: left;
    margin-top: 15px;
    margin-left: 10px;
    font-size: 12px;
    color: #9e9e9e;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionMeta .TransactionIdentifier {
    margin-right: 5px;
}

.TransactionWrapper .TransactionList .TransactionItem .TransactionMeta svg {
    font-size: 14px;
    color: #9e9e9e;
    vertical-align: top;
    margin-right: 5px;
}
